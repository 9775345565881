import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_summary_charts = _resolveComponent("summary-charts")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_summary_charts, {
        key: 0,
        model: _ctx.model,
        onDownloadPDF: _ctx.downloadPDF,
        onExportCsv: _ctx.exportCsv
      }, null, 8, ["model", "onDownloadPDF", "onExportCsv"]))
    : _createCommentVNode("", true)
}